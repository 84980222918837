<template>
  <sign-page
    title-text="其他证书管理"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :title-menus="[{key: 'add', label: '新增'}]"
    @dataFileUpdate="dataFileUpdate"
    :tableActions="tableActions"
    table-size="large"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerCertificateRequest as request
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.applyEdit || this.$authFunsProxy.applyAdd) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书名称',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.workerList,
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '编号',
          dataType: Number,
          field: 'id',
          sort: true,
          width: 80
        },
        {
          title: '证书名称',
          field: 'mType',
          sort: true
        },
        {
          title: '证书编码',
          field: 'code',
          sort: true
        },
        {
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '员工工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '性别',
          field: 'sex',
          sort: true,
          width: 100
        },
        {
          title: '出生日期',
          field: 'birth',
          sort: true,
          dataType: Date,
          width: 150,
          render: (h, rowData) => {
            return h('div', rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.birth ? rowData.birth.slice(0, 10) : '-'
          }
        },
        {
          title: '身份证号',
          field: 'idNo',
          wdith: 185,
          sort: true
        },
        {
          title: '证件状态',
          field: 'status',
          sort: true
        // },
        // {
        //   title: '扫描件状态',
        //   field: 'imgStatusText',
        //   sort: true
        // },
        // {
        //   title: '最近更新扫描件时间',
        //   field: 'imgUploadTime',
        //   sort: true,
        //   dataType: Date
        }]
        return data
      }
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {type: 'other'}
    },
    async loadData () {
      let parm = {
        type: 'other'
      }
      let data = await request.get(parm)
      data.forEach(v => {
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
